import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/admin/layout"
import Modal from "../../components/common/modal"
import request, { getUserRole } from "../../request"

const markdowns = [
  ["introduction/history.md", "公會簡介/公會沿革"],
  ["introduction/bylaws.md", "公會簡介/公會章程"],
  ["introduction/organization.md", "公會簡介/組織服務"],
  ["introduction/director.md", "公會簡介/理監事"],
  ["introduction/future.md", "公會簡介/公會展望"],
  ["industry/law.md", "產業訊息/法令政策"],
  ["industry/technology.md", "產業訊息/技術通報"],
  ["industry/product.md", "產業訊息/最新產品"],
  ["property/main.md", "產業訊息/他山之石"],
  ["property/detail-1.md", "產業訊息/他山之石/美國瀝青科技中心"],
  ["property/detail-2.md", "產業訊息/他山之石/美國瀝青協會"],
  ["property/detail-3.md", "產業訊息/他山之石/美國材料與試驗學會"],
  ["property/detail-4.md", "產業訊息/他山之石/瀝青鋪築技術員協會"],
  ["property/detail-5.md", "產業訊息/他山之石/生命週期評估"],
  ["property/detail-6.md", "產業訊息/他山之石/生命週期評估技術應用於國內"],
  ["property/detail-7.md", "產業訊息/他山之石/生命週期成本分析"],
  ["property/detail-8.md", "產業訊息/他山之石/試驗路面介紹"],
  ["property/detail-9.md", "產業訊息/他山之石/新的瀝青分級法"],
  ["property/detail-10.md", "產業訊息/他山之石/APA"],
  ["associator/select-1-1.md", "會員介紹/每週一廠/拓洋瀝青股份有限公司"],
  ["associator/select-1-2.md", "會員介紹/每週一廠/鴻運瀝青化學股份有限公司"],
  [
    "associator/select-2-4.md",
    "會員介紹/每週一廠/建中工程股份有限公司(新竹廠)",
  ],
  [
    "associator/select-3-2.md",
    "會員介紹/每週一廠/開磐企業股份有限公司(大洲廠)",
  ],
  ["associator/select-4-4.md", "會員介紹/每週一廠/崧峰實業股份有限公司"],
  ["associator/select-4-5.md", "會員介紹/每週一廠/陸輝瀝青工業股份有限公司"],
  ["associator/select-5-2.md", "會員介紹/每週一廠/坤穎企業有限公司"],
  ["associator/select-5-4.md", "會員介紹/每週一廠/蔡明高股份有限公司"],
  ["associator/select-5-5.md", "會員介紹/每週一廠/谷昌瀝青工業股份有限公司"],
  ["associator/join.md", "會員介紹/加入會員"],
  ["newsletter.md", "瀝青簡訊"],
  ["website.md", "相關網站"],
]

class Markdown extends React.Component {
  state = {
    content: "",
    reveal: false,
    index: -1,
  }
  componentDidMount() {
    const role = getUserRole()
    if (role === 0) return navigate("/admin/login")
    if (role !== 1) return navigate("/admin/announcement")
  }

  showDetail = index => {
    request.getMarkdown(index).then(res => {
      this.setState({ reveal: true, index, content: res.data.content })
    })
  }

  updateContent = () => {
    const { content, index } = this.state
    request
      .putMarkdown(index, content)
      .then(() => {
        alert("更新成功")
        this.setState({ reveal: false })
      })
      .catch(() => alert("更新失敗"))
  }

  render() {
    if (!getUserRole()) return null
    const { reveal, content } = this.state
    return (
      <Layout>
        <table className="ad-user__table ad-user__table--hover">
          <thead>
            <tr>
              <th>檔案名稱</th>
            </tr>
          </thead>
          <tbody>
            {markdowns.map((markdown, index) => (
              <tr key={index} onClick={() => this.showDetail(index)}>
                <td>{markdown[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          reveal={reveal}
          size="xl"
          header="編輯檔案"
          onCancel={() => this.setState({ reveal: false })}
          onConfirm={this.updateContent}
          textConfirm="更新"
        >
          <div className="ad-user__modal">
            <form className="ad-user__form">
              <textarea
                style={{ minHeight: "600px" }}
                value={content}
                onChange={e => {
                  this.setState({ content: e.target.value })
                }}
              />
            </form>
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default Markdown
